#footer {
  @include susy-clearfix;
  background: $ui-site-color-4;
  color: white;
  padding: 20px;

  > .block {
    @include media-md {
      @include gallery(4);
    }
  }

  #germes-header-search {
    margin-bottom: rem(32);
  }
}

#copyrights {
  background: $ui-site-color-1;
  padding: 10px 20px;
}