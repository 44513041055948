.commerce-product-knife.view-mode-teaser {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $ui-site-color-1;

  .photo {
    position: absolute;
    width: 105px;
    left: 0;
    top: 0;
  }

  .content {
    padding-left: 115px;
    min-height: 65px;

    .title {
      font-weight: bold;

      .commerce-product-title-label {
        display: none;
      }
    }

    .sku {
      font-style: italic;

      .commerce-product-sku-label {
        display: none;
      }
    }

    .field-name-commerce-price {
      .field-item {
        @include icon($fa-var-credit-card);
        font-weight: bold;
      }
    }
  }
}