.view-taxonomy-terms-catalog-groups {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $ui-site-color-5;

  > .content {
    .group-info {
      margin-bottom: 15px;

      .group-title {
        @extend h2;
        color: $ui-site-color-3;
      }

      .group-description {

      }
    }
  }
}

.view-taxonomy-terms-catalog-categories {
  > .content {
    @include susy-clearfix;

    .taxonomy-term {
      @include media-xs {
        @include gallery(6);
      }

      @include media-sm {
        @include gallery(4);
      }

      @include media-md {
        @include gallery(3);
      }
    }
  }
}

.view-taxonomy-terms-frontpage-catalog {
  > .content {
    @include susy-clearfix;

    .taxonomy-term {
      @include media-xs {
        @include gallery(6);
      }

      @include media-sm-md {
        @include gallery(4);
      }
    }
  }
}

.view-taxonomy-terms-catalog-groups {
  > .content {
    @include susy-clearfix;

    > .taxonomy-term {
      @include media-xs {
        @include gallery(6);
      }

      @include media-sm {
        @include gallery(4);
      }

      @include media-md {
        @include gallery(2.4);
      }
    }
  }
}

.view-taxonomy-terms-frontpage-terms {
  > .content {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(4, 1fr);

    @include media-xs {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }
  }
}