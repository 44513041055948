/**
 * @file
 * Button styles.
 */

/**
 * Default styles and resets for all buttons used as base for all others.
 */
.btn {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  font-weight: $btn-font-weight;
  padding: $padding-base-vertical $padding-base-horizontal;
  line-height: $line-height-base;
  box-sizing: border-box;
  border-radius: $btn-border-radius;
  text-align: center;
  color: color-contrast($btn-default-bg);
  @include button-variant($btn-default-bg, $btn-default-border);
}

/**
 * Submit and primary button style.
 */
.form-submit,
.btn-primary {
  @extend .btn;
  @include button-variant($btn-primary-bg, $btn-primary-border);
}

/**
 * Warning and preview buttons style.
 */
.btn-warning,
#edit-preview {
  @extend .btn;
  @include button-variant($btn-warning-bg, $btn-warning-border);
}

/**
 * Danger and delete button style.
 */
.btn-danger,
#edit-delete {
  @extend .btn;
  @include button-variant($btn-danger-bg, $btn-danger-border);
}

/**
 * Success button style.
 */
.btn-success {
  @extend .btn;
  @include button-variant($btn-success-bg, $btn-success-border);
}

/**
 * Info button style.
 */
.btn-info {
  @extend .btn;
  @include button-variant($btn-info-bg, $btn-info-border);
}
