.paragraphs-item-service-variants {
  @include susy-clearfix;
  @include flexbox;
  @include flex-wrap(wrap);

  > .entity {
    @include media-xs {
      width: 100%;
      margin: 0 8px 15px 8px;
    }

    @include media-sm {
      width: calc(100% / 2 - 16px);
      margin: 0 8px 15px 8px;
    }

    @include media-md {
      width: calc(100% / 3 - 16px);
      margin: 0 8px 15px 8px;
    }
  }
}