#block-block-5 {
  .worktime {
    @include flexbox;

    .blocks {
      width: 40px;
      margin-right: 8.5px;

      .day {
        width: 100%;
        height: 10px;
        background: $ui-site-color-1;
        margin-bottom: 4px;

        &.inactive {
          background: $ui-site-color-3;
        }
      }
    }
  }
}