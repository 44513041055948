.product-other-teaser {
  @extend .product-knife-teaser;
}

.product-other-full {
  @extend .product-knife-full;

  .first-pane {
    > .left {
      .field-field-product-other-photos {
        .first-photo {
          margin-bottom: 15px;
        }

        .other-photos {
          @include susy-clearfix;

          .photoswipe {
            @include gallery(4);
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}