.apply-to-dealer {
  padding: 30px;
  background: $ui-site-color-2;
  border: 2px solid $ui-site-color-3;
  color: white;
  text-align: center;

  &__title {
    margin-top: 0;
    font-size: 30px;
    line-height: 1.4;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__apply-button {
    display: block;
    border: 2px solid white;
    border-radius: 4px;
    color: white;
    padding: 6px 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 16px;

    &:hover {
      background-color: white;
      color: $text-color;
    }
  }

  &__phone {
    background: $ui-site-color-3;
    display: block;
    border: 2px solid transparent;
    border-radius: 4px;
    color: white;
    padding: 6px 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
