.view-search-api-product-displays-catalog-products,
.view-search-api-product-displays-knifes-in-categories,
.view-search-api-product-displays-others-in-category,
.view-search-api-product-displays-klinki-page,
.view-search-api-product-displays-blade-in-category,
.view-search-api-product-displays-search-products {
  > .filters {
    .views-widget {
      .form-item {
        padding: 0;
        margin: 0;
        display: flex;

        @include media-xs-sm {
          flex-flow: wrap;
        }
      }

      label {
        @include icon($fa-var-bars);
        margin-right: 10px;

        @include media-xs-sm {
          width: 100%;
        }
      }

      .form-radios {
        display: flex;

        .form-type-bef-link {
          margin-right: 8px;

          a {
            border-bottom: 1px solid $link-color;
          }

          &.selected-edit-sort-search-api-aggregation-2-asc,
          &.selected-edit-sort-created-asc {
            @include icon($fa-var-caret-down, after);

            a {
              font-weight: bold;
            }
          }

          &.selected-edit-sort-search-api-aggregation-2-desc,
          &.selected-edit-sort-created-desc {
            @include icon($fa-var-caret-up, after);

            a {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  > .content {
    display: grid;
    grid-gap: 24px;

    @include media-xs {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-md {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
