$mobile-menu-icons: (
  '/catalog': $fa-var-th-list,
  '/service': $fa-var-newspaper-o,
  '/sertifikaty': $fa-var-file-text,
  '/dostavka-i-oplata': $fa-var-credit-card,
  '/contact': $fa-var-envelope,
  '/about': $fa-var-info,
  '/video': $fa-var-video-camera,
  '/otzyvy': $fa-var-pencil
);

#block-menu-menu-mobile-menu {
  > .title {
    display: none;
  }
  
  .menu {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      @each $item_id, $icon in $mobile-menu-icons {
        a[href="#{$item_id}"] {
          @include icon($icon);

          &:before {
            width: 20px;
            text-align: center;
          }
        }
      }

      a {
        color: $ui-site-color-2;
        font-size: 17px;
        display: block;
        padding: 10px 15px;
      }
    }
  }
}