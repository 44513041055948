#commerce-checkout-form-checkout {
  .views-table {
    @extend .table;
    @extend .table-striped;
  }

  fieldset:not(.checkout-buttons) {
    background: white;
    padding-top: 24px;

    .fieldset-legend {
      top: 8px;
      left: 16px;
    }
  }

  > div {
    display: grid;
    grid-gap: 24px;
    grid-template-areas:
      "cart cart"
      "profile delivery";

    @include media-xs {
      grid-template-areas: "cart" "profile" "delivery";
      grid-gap: 0;
    }
  }

  .cart_contents {
    grid-area: cart;
  }

  .customer_profile_billing {
    grid-area: profile;
  }

  #commerce-shipping-service-ajax-wrapper {
    grid-area: delivery;
  }

  .checkout-buttons {
  }
}

#commerce-checkout-form-review {
  .views-table,
  .checkout-review {
    @extend .table;
    @extend .table-striped;
  }

  #edit-checkout-review {
    background: white;
  }

  fieldset:not(.checkout-buttons) {
    background: white;
    padding-top: 24px;

    .fieldset-legend {
      top: 8px;
      left: 16px;
    }
  }
}

#edit-commerce-payment-payment-method {
  justify-content: stretch;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @include media-xs {
    grid-template-columns: 1fr;
  }

  .form-item-commerce-payment-payment-method {
    height: unset !important;
  }

  .payment-method-custom-item {
    cursor: pointer;
    background-color: #f8f8f8;
    border: 2px solid #4f4e4a;
    border-radius: 4px;
    text-align: center;
    padding: 8px;
    font-weight: bold;
    height: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
  }

  [name^="commerce_payment"] {
    display: none;
  }

  [name^="commerce_payment"]:checked + label {
    .payment-method-custom-item {
      background-color: rgba(#56ab2f, .1);
      border-color: #56ab2f;
    }
  }
}

#edit-commerce-payment-payment-details-payment-method {
  @include susy-clearfix;

  .form-item {
    @include media-md {
      @include gallery(3);
    }

    @include media-xs-sm {
      @include gallery(6);
    }
    cursor: pointer;
    margin-bottom: 16px;
    
    input {
      display: none;
    }

    input:checked + label {
      font-weight: bold;
      background: #CCFF90;
      border: 1px solid #64DD17;
    }

    label {
      cursor: pointer;
      display: block;
      padding: 16px;
      border: 1px solid transparent;

      &:hover {
        background: #F1F8E9;
        border: 1px solid #C5E1A5;
      }
      
      img {
        margin-bottom: 8px;
      }
    }
  }
}