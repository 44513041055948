.testimonial-teaser {
  //border: 1px solid $ui-site-color-5;

  * {
    min-width: 0;
  }

  &__header {
    display: flex;
    margin-bottom: 16px;
  }

  &__photo {
    flex: 0 0 auto;
    margin-right: 16px;
    width: 80px;
  }

  &__meta {
    flex: 1 auto;
    width: auto;
  }

  &__name {
    font-weight: bold;
  }

  &__city {
    color: #5C5C5C;
  }

  &__created-and-rating {
    display: flex;
    margin-bottom: 8px;
  }

  &__rating {
    margin-left: auto;
    display: flex;
  }

  .germes-rating {
    margin-left: auto;
  }

  .field-name-field-testimonial-photo {
    border-radius: 50%;
    overflow: hidden;
  }
}

.testimonial-teaser .field-name-body {
  overflow: hidden;
}

.testimonial-teaser__more {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  font-size: 14px;
}

.testimonial-teaser__more span {
  text-align: center;
  border-bottom: 1px dashed $ui-site-color-3;
  color: $ui-site-color-3;
  cursor: pointer;
}

.testimonial-teaser__name-and-city {
  margin-top: 8px;
}

.testimonial-teaser__content iframe {
  max-width: 100%;
}