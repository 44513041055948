.product-price {
  position: relative;
}

.product-price__main {
  position: relative;
  padding: 16px;
}

.product-price__main,
.product-price__all-item {
  text-align: right;
}

.product-price__popup {
  font-size: 8px;
  line-height: 22px;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 16px;
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  @include icon($fa-var-chevron-down);
  transform: translateY(-50%);
  text-align: center;
  border: 1px solid black;
  border-radius: 100%;
  background-color: #fff;
}

.product-price__popup::before {
  margin: 0;
  vertical-align: middle;
}

.product-price__amount {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  color: $ui-site-color-3;
}

.product-price__all {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.product-price.js-popup-visible .product-price__all,
.product-price__all:hover {
  display: block;
}
