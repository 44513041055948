/**
 * Tabs style.
 */
ul.tabs {
  @include susy-clearfix;
  // Primary tabs.
  &.primary {
    background: $primary-tabs-bg;
    margin: $primary-tabs-margin;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid $primary-tabs-inactive-color;

    li {
      float: left;
      &.active {
        margin-bottom: -1px;
        border-bottom: 3px solid $primary-tabs-active-color;
      }
      a {
        color: $primary-tabs-inactive-color;
        display: block;
        padding: .5rem .75rem;
        border: none;
        text-decoration: none;
      }

      &.active a,
      &:hover a {
        color: $primary-tabs-active-color;
        transition: all 500ms;
      }
    }
  }

  // Secondary.
  &.secondary {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #E0E0E0;

    &:after {
      content: '';
      display: block;
      float: none;
      clear: both;
    }

    li {
      float: left;
      display: block;
      border: none;
      padding: 0;
      margin: 0;

      a {
        display: block;
        margin: 0;
        color: #4E5665;
        padding: 10px 15px;
        border: none;
        box-shadow: none;
        transition: box-shadow 200ms;
      }

      &.active a {
        background: white;
        box-shadow: inset 0px -2px 0px #6D6D6D;
      }

      &.hover a {
        box-shadow: inset 0px -2px 0px #6D6D6D;
        transition: box-shadow 200ms;
      }
    }
  }

  &:last-child {
    margin-bottom: 15px;
  }
}

// Vertical tabs.
.vertical-tabs {
  background: $vertical-tabs-bg;
  .vertical-tabs-list {
    background: $vertical-tabs-list-bg;
    border: $vertical-tabs-list-border;
    * {
      text-decoration: none !important;
      outline: 0 !important;
    }

    .vertical-tab-button {
      border: none;
      a {
        color: $vertical-tabs-button-color;
        border: none;
      }

      &.selected {
        background: $vertical-tabs-button-bg-selected;
        strong, .summary {
          color: $vertical-tabs-button-color-selected;
          text-shadow: $vertical-tabs-button-text-shadow;
        }
      }
    }
  }
}

