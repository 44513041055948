/**
 * @file
 */

$message-error-bg:     #FFF6F6;
$message-error-border: #E0B4B4;
$message-error-color:  #9F3A38;

$message-status-bg:     #FCFFF5;
$message-status-border: #A3C293;
$message-status-color:  #2C662D;

$message-warning-bg:     #FFFAF3;
$message-warning-border: #C9BA9B;
$message-warning-color:  #573A08;
