$map: (
  'vk': ($fa-var-vk, #45668E),
  'facebook': ($fa-var-facebook, #3B5999),
  'ok': ($fa-var-odnoklassniki, #ED812B),
  'instagram': ($fa-var-instagram, #DA306E),
        'youtube': ($fa-var-youtube, #FF0000)
);

#block-block-3 {
  a {
    color: white;
    text-decoration: underline;
  }
  .socials {
    .item {
      display: inline-block;
      color: white;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      font-size: 24px;
      transition: background 300ms;

      &:hover {
        transition: background 300ms;
      }

      @each $class, $var in $map {
        &.#{$class} {
          background: nth($var, 2);

          &:hover {
            background: lighten(nth($var, 2), 10%);
          }

          @include icon(nth($var, 1));

          &:before {
            margin: 0;
          }
        }
      }
    }
  }

  #germes-header-search {
    max-width: 228px;
  }
}