/**
 * @file
 * Radio.
 */

// Glisseo radios.
input[type="radio"] {
  &.glisseo-radio {
    display: none;

    &:disabled {
      ~ .glisseo-radio-wrapper {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }

  &.glisseo-radio:checked {
    ~ .glisseo-radio-wrapper {
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.22) inset;
      transition: all 0.17s ease 0s;

      .glisseo-radio-mark {
        opacity: 1;
        transition: all 0.17s ease 0s;
      }
    }
  }
}

.glisseo-radio-wrapper {
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border-radius: 30px;
  border: 1px solid #a0a0a0;
  box-shadow: none;
  background: rgb(252, 252, 252);
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZjZmNmYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmMGYwZjAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, rgba(252, 252, 252, 1) 0%, rgba(240, 240, 240, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(252, 252, 252, 1)), color-stop(100%, rgba(240, 240, 240, 1)));
  background: -webkit-linear-gradient(top, rgba(252, 252, 252, 1) 0%, rgba(240, 240, 240, 1) 100%);
  background: -o-linear-gradient(top, rgba(252, 252, 252, 1) 0%, rgba(240, 240, 240, 1) 100%);
  background: -ms-linear-gradient(top, rgba(252, 252, 252, 1) 0%, rgba(240, 240, 240, 1) 100%);
  background: linear-gradient(to bottom, rgba(252, 252, 252, 1) 0%, rgba(240, 240, 240, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#f0f0f0', GradientType=0);
  transition: all 0.17s ease 0s;

  &:hover {
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.11);
    transition: all 0.17s ease 0s;
  }

  .glisseo-radio-mark {
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background: none repeat scroll 0% 0% rgba(72, 72, 72, 1);
    margin: 5px auto;
    opacity: 0;
    transition: all 0.17s ease 0s;
  }
}
