.paragraphs-item-video-youtube-gallery {
  margin-bottom: 10px;
  @for $i from 2 through 4 {
    .videos.per-row-#{$i} {
      @include susy-clearfix;

      .video {
        $gallery-size: 12 / $i;
        @include gallery($gallery-size);
      }
    }
  }

  .videos .video {
    margin-bottom: 15px;
    border: 1px solid $ui-site-color-5;
  }
}