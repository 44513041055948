#block-menu-menu-footer-links {
  .menu {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;

    li {
      margin-bottom: 5px;

      a {
        color: white;
        font-size: 15px;
        border-bottom: 1px solid transparent;

        &:hover {
          border-color: rgba(white, .5);
        }
      }
    }
  }
}