#germes-header-search {
  position: relative;
  display: flex;

  .text {
    background: white;
    //border: 1px solid $ui-site-color-5;
    height: 28px;
    border-radius: 8px 0 0 8px;
  }

  .button {
    background: $ui-site-color-2;
    color: white;
    border-radius: 0 8px 8px 0;
    border: none;
  }
}