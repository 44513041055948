.view-commerce-cart-form-default {
  .views-table {
    @extend .table;
    @extend .table-striped;
    background: white;
  }

  .also-to-buy {
    .items {
      @include susy-clearfix;

      .product-other-teaser,
      .product-knife-teaser {
        @include media-xs {
          @include gallery(6);
        }

        @include media-sm-md {
          @include gallery(3);
        }
      }
    }
  }
}
