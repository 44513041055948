.vocabulary-product-tags.view-mode-teaser {
  margin-bottom: 15px;

  .photo {
    margin-bottom: 10px;
  }

  .title {
    @include font-size(18);
    font-weight: bold;
    text-align: center;
    margin: 0;

    a {
      color: $text-color;
    }
  }
}