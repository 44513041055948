#mobile-cart {
  @include icon($fa-var-shopping-basket);
  @include font-size(18);
  padding: 11px 15px;
  position: absolute;
  right: 0;
  top: 0;
  color: white;

  &:before {
    margin: 0;
  }

  .quantity {
    position: absolute;
    @include font-size(12);
    right: rem(7);
    top: rem(6);
    background: white;
    border-radius: 50px;
    color: $ui-site-color-3;
    font-weight: bold;
    display: block;
    width: rem(17);
    text-align: center;
    box-shadow: 0 0 0 2px $ui-site-color-3;
  }
}

#mobile-telephone {
  @include icon($fa-var-phone-square);
  @include font-size(18);
  padding: 11px 15px;
  position: absolute;
  right: 50px;
  top: 0;
  color: white;

  &:before {
    margin: 0;
  }
}