.news-teaser {
  margin-bottom: 15px;

  .promo-image {
    margin-bottom: 10px;
  }

  .title {
    margin: 0 0 10px;
    @include font-size(16);

    @include media-md {
      @include font-size(20);
    }

    a {
      border-bottom: 1px solid $ui-site-color-3;
    }
  }

  .teaser {
    @include font-size(14);
  }
}

.article-full {
  .promo {
    margin-bottom: 15px;
  }
}