/**
 * @file
 * Here styled base elements and over fixes.
 */

// Disable Mozilla's border.
:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

::selection {
  background: $selection-bg;
  color: $selection-color;
}

body {
  background: $body-bg;
  font-family: $font-family;
}

// Fix whitespace under image.
img {
  max-width: 100%;
  height: auto;
  display: block;
  vertical-align: bottom;
}

label {
  display: block;
  font-weight: bold;
  margin: 0 0 0.1rem 0;

  &.option {
    font-weight: normal;
    display: inline;
  }
}

#admin-menu .admin-menu-search input {
  height: auto;
}
