.paragraphs-item-additional-param {
  padding: 3px;

  .content {
    display: flex;
    align-items: center;

    .field-name-field-textfield {
      flex: 0 1 49.15254%;
      margin-right: 1.69492%;
    }

    .field-name-field-textfield-value {
      flex: 1 1 49.15254%;
      text-align: right;
    }
  }
}