.field-collection-item-field-service-variants {
  background: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 3px 3px rgba(0, 0, 0, 0.08);

  .title {
    background: $ui-site-color-5;
    color: white;
    text-align: center;
    padding: 6px;
    font-size: 18px;
  }

  .price {
    background: $ui-site-color-1;
    text-align: center;
    padding: 6px;
  }

  .description {
    padding: 10px;
  }

  .order-button {
    @include button-variant($ui-site-color-3);
    border-radius: 0;
    width: 100%;
  }
}