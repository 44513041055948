.view-nodes-catalog-products {
  > .content {
    @include susy-clearfix;

    .product-knife-teaser,
    .product-other-teaser,
    .product-blade-teaser {
      @include gallery(3);
    }
  }
}

.view-nodes-similar-products {
  > .content {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(4, 1fr);

    @include media-xs {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }
  }
}

.view-nodes-articles {
  > .content {
    @include susy-clearfix;

    .article-teaser,
    .news-teaser {
      margin-bottom: 32px;

      @include media-xs {
        @include gallery(6);
      }

      @include media-sm-md {
        @include gallery(4);
      }
    }
  }
}

.view-nodes-services {
  > .content {
    @include susy-clearfix;

    .service-teaser {
      @include media-sm {
      }

      @include media-md {
        @include gallery(6);
      }
    }
  }
}

.view-nodes-bought-with-that {
  > .content {
    @include susy-clearfix;

    .product-knife-teaser,
    .product-other-teaser,
    .product-blade-teaser {
      @include media-xs {
        @include gallery(6);
      }

      @include media-sm-md {
        @include gallery(3);
      }
    }
  }
}

.view-nodes-frontpage-reviews {
  > .content {
    @include susy-clearfix;

    .testimonial-teaser {
      @include media-sm-md {
        @include gallery(4);
      }
    }
  }
}

.view-nodes-frontpage-bestsellers {
  > .content {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(4, 1fr);

    @include media-xs {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }
  }
}

.view-nodes-articles-in-category {
  > .content {
    @include susy-clearfix;

    .article-teaser {
      @include media-xs {
        @include gallery(6);
      }

      @include media-sm-md {
        @include gallery(4);
      }
    }
  }
}

.view-nodes-best-deals {
  > .content {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(4, 1fr);

    @include media-xs {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }
  }
}

.view-nodes-testimonials-page {
  > .header {
    margin-bottom: 32px;

    .testimonial-add {
      @include button-variant($ui-site-color-3, $ui-site-color-3);
      font-weight: bold;
      display: table;
      margin: 0 auto;
    }
  }

  > .content {
    display: grid;
    grid-gap: 24px;
    grid-row-gap: 40px;
    grid-template-columns: 1fr;

    @include media-sm-md {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.view-nodes-testimonials-for-product .content {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
}