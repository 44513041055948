#block-block-7 {
  background: #E8E8E8;
  padding: 10px;
  margin-bottom: 15px;

  > .title {
    color: $ui-site-color-3;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
