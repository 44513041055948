.taxonomy-term.vocabulary-product-steel-mark-category.view-mode-teaser,
.taxonomy-term.vocabulary-product-knife-type-tags.view-mode-teaser,
.taxonomy-term.vocabulary-product-knife-purpose-tags.view-mode-teaser,
.taxonomy-term.vocabulary-product-author-work-tags.view-mode-teaser,
.taxonomy-term.vocabulary-product-other-tags.view-mode-teaser,
.taxonomy-term.vocabulary-product-other-categories.view-mode-teaser,
.taxonomy-term.vocabulary-product-blade-categories.view-mode-teaser {
  margin-bottom: 15px;
  min-width: 0;

  .image {
    margin-bottom: 5px;
  }

  .title {
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
  }
}
