#header {
  .pc-header {
    @include susy-clearfix;
    background: $ui-site-color-1;
    padding: 20px;
    display: none;

    @include media-md {
      display: block;
    }

    #block-branding-block-branding {
      @include span(3);

      @include media-lg {
        width: 400px;
        margin-right: 60px;
      }
    }

    #block-block-1 {
      @include span(4);

      @include media-lg {
        @include span(4);
      }
    }

    #block-germes-shopping-cart {
      @include span(3 last);
    }
  }
  
  .mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    z-index: 99;
    background: $ui-site-color-2;
    height: 48px;
    color: white;
    
    @include media-md {
      display: none;
    }

    .site-name {
      display: block;
      width: 100%;
      color: white;
      font-weight: bold;
      z-index: 0;
      padding: 7px 0 7px 46px;

      .first,
      .second {
        line-height: 17px;
        font-family: $font-family-condensed;
      }
    }

    .menu-toggler {
      @include icon($fa-var-bars);
      position: absolute;
      top: 0;
      left: 0;
      width: 38px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      cursor: pointer;
      background: $ui-site-color-3;

      &:before {
        margin: 0;
      }
    }
  }
}

#top-line {
  padding: 10px 20px;
  font-size: 18px;
  display: none;

  @include media-md {
    display: block;
  }
}

#header-line-image {
  width: 100%;
  display: none;

  @include media-lg {
    display: block;
  }
}