/**
 * @file
 * Buttons background, borders and colors.
 */

$btn-font-weight:      normal !default;
$btn-font-color-dark:  #333 !default;
$btn-font-color-light: #fff !default;

$btn-default-bg:     #fff !default;
$btn-default-border: #ccc !default;
$btn-border-radius:  $border-radius-small;

$btn-primary-bg:     $ui-site-color-4 !default;
$btn-primary-border: transparent !default;

$btn-success-bg:     $brand-success !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;

$btn-warning-bg:     $brand-warning !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;

$btn-danger-bg:     $brand-error !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;

$btn-info-bg:     $brand-info !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;
