/**
 * @file
 * Checkboxes.
 */

// Glisseo checkbox.
input[type="checkbox"] {
  &.glisseo-checkbox {
    display: none;

    &:disabled  {
      ~ .glisseo-checkbox-switcher {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }

  &.glisseo-checkbox:checked {
    ~ .glisseo-checkbox-switcher {
        .glisseo-checkbox-switcher-container {
          left: 1px;
          transition: all 0.17s ease 0s;
        }
    }
  }
}

.glisseo-checkbox-switcher {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  border: 1px solid #D0D0D0;
  border-radius: 3px;
  overflow: hidden;
  width: 76px;
  background: rgb(233, 234, 237);
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U5ZWFlZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNGY0ZjYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, rgba(233, 234, 237, 1) 0%, rgba(244, 244, 246, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(233, 234, 237, 1)), color-stop(100%, rgba(244, 244, 246, 1)));
  background: -webkit-linear-gradient(top, rgba(233, 234, 237, 1) 0%, rgba(244, 244, 246, 1) 100%);
  background: -o-linear-gradient(top, rgba(233, 234, 237, 1) 0%, rgba(244, 244, 246, 1) 100%);
  background: -ms-linear-gradient(top, rgba(233, 234, 237, 1) 0%, rgba(244, 244, 246, 1) 100%);
  background: linear-gradient(to bottom, rgba(233, 234, 237, 1) 0%, rgba(244, 244, 246, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9eaed', endColorstr='#f4f4f6', GradientType=0);
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  line-height: 23px;
  margin: 0 0 0.25rem 0;

  .glisseo-checkbox-switcher-container {
    width: 200px;
    position: relative;
    left: -38px;
    transition: all 0.17s ease 0s;
  }

  .left {
    width: 37px;
    height: 23px;
    float: left;
    color: #5890ff;
    text-shadow: 0px 1px 0 white;
  }

  .mid {
    width: 37px;
    height: 23px;
    float: left;
    background: rgb(254, 254, 254);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZlZmVmZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNmY3ZjgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(254, 254, 254, 1) 0%, rgba(246, 247, 248, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(254, 254, 254, 1)), color-stop(100%, rgba(246, 247, 248, 1)));
    background: -webkit-linear-gradient(top, rgba(254, 254, 254, 1) 0%, rgba(246, 247, 248, 1) 100%);
    background: -o-linear-gradient(top, rgba(254, 254, 254, 1) 0%, rgba(246, 247, 248, 1) 100%);
    background: -ms-linear-gradient(top, rgba(254, 254, 254, 1) 0%, rgba(246, 247, 248, 1) 100%);
    background: linear-gradient(to bottom, rgba(254, 254, 254, 1) 0%, rgba(246, 247, 248, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fefefe', endColorstr='#f6f7f8', GradientType=0);
    border-width: 0 1px 0 1px;
    border-style: solid;
    border-color: #D0D0D0;
    border-radius: 3px;
  }

  .right {
    width: 37px;
    height: 23px;
    float: left;
    color: #555;
  }

  ~ .description {
    margin-left: 80px;
  }
}

