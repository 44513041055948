$map: (
  'vk': ($fa-var-vk, #45668E),
  'facebook': ($fa-var-facebook, #3B5999),
  'ok': ($fa-var-odnoklassniki, #ED812B),
  'instagram': ($fa-var-instagram, #DA306E),
  'youtube': ($fa-var-youtube, #FF0000),
  'telegram': ($fa-var-telegram, #0088cc),
);

.header-phones {
  &__content {
    display: flex;

    &-left {
      flex: 1 60%;
      width: 60%;
    }

    &-right {
      flex: 1 40%;
      width: 40%;
      text-align: right;
    }
  }

  &__label {
    text-transform: uppercase;
    color: #808080;
    font-size: 13px;
  }

  &__callback {
    margin-bottom: 6px;
    a {
      @include button-variant($ui-site-color-3);
      padding: 4px 8px;
    }
  }

  &__phone {
    margin-bottom: 4px;

    span {
      font-weight: bold;
    }

    &--primary {
      color: $ui-site-color-3;
      font-weight: bold;
      font-size: 26px;
    }
  }
}

.header-phones__social {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: auto;
  justify-content: flex-end;
}

.header-phones__social-item {
  color: white;
  font-size: 18px;
  padding: 4px 8px;
  display: block;
  margin-left: 8px;
  border-radius: 4px;
}

@each $class, $data in $map {
  .header-phones__social-item--#{$class} {
    background: nth($data, 2);
    @include icon(nth($data, 1));

    &:hover {
      background: lighten(nth($data, 2), 10%);
      color: white;
    }

    &::before {
      margin: 0;
    }
  }
}

.header-phones__search {
  margin-top: 16px;
}
