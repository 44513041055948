.service-teaser {
  margin-bottom: 20px;
  position: relative;

  .promo-image {
    @extend .material-shadow-1-with-hover;
    position: absolute;
    z-index: 3;
    width: 65px;
    left: 15px;
    top: 15px;

    @include media-sm-md {
      width: 180px;
      left: 15px;
      top: 40px;
    }
  }

  > .header {
    position: relative;
    height: 180px;
    overflow: hidden;
    background: black;

    &:hover {
      .content .title a {
        border-bottom: 1px solid rgba(white, .5);
        cursor: pointer;
      }
    }

    .background {
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      background-size: cover;
      z-index: 0;
      filter: blur(14px);
      opacity: .7;

      img {
        width: 100%;
      }
    }

    > .content {
      position: relative;
      z-index: 1;
      padding: 15px 15px 15px 95px;

      @include media-sm-md {
        padding: 35px 15px 15px 220px;
      }

      .title {
        margin-top: 0;
        font-size: 20px;
        line-height: 22px;

        @include media-xs {
          font-size: 18px;
          line-height: 20px;
        }

        a {
          color: white;
        }
      }

      .text {
        color: white;
        font-size: 13px;
        line-height: 15px;

        @include media-xs {
          font-size: 13px;
          line-height: 14px;
        }
      }
    }
  }
}