/**
 * @file
 */

$form-item-padding: 10px !default;

$input-height-base:  $line-height-computed + ($padding-base-vertical * 2) + 2 !default;
$input-height-large: ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2 !default;
$input-height-small: floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2 !default;
// Input default state.
$input-bg:            white;
$input-border-color:  rgba(34,36,38,.15);
$input-box-shadow:    none;
$input-border-radius: $border-radius-small;
// input:focus
$input-bg-focus:           white;
$input-border-color-focus: #85B7D9;
$input-box-shadow-focus:   none;
// input:disabled
$input-bg-disabled:     rgb(250, 250, 250);
$input-color-disabled:  rgba(163, 163, 163, 1);

// Select default state.
$select-box-shadow:   none;
$select-border-color: $input-border-color;
// select:focus
$select-border-color-focus: $input-border-color-focus;
$select-box-shadow-focus:   $input-box-shadow-focus;
