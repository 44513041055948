#commerce-checkout-form-checkout {
  .addressfield-container {
    .form-item-customer-profile-billing-commerce-customer-address-und-0-administrative-area {
      display: none;
    }
  }

  #edit-buttons {
    margin: 0;
    padding: 0;

    .fieldset-wrapper {
      margin: 0;
      padding: 0;
    }
  }

  #edit-continue {
    @include button-variant(#64dd17);
    font-size: 18px;
    padding: 8px 16px;
  }
}

.entityform.entitytype-review-form {
  .name-and-city {
    display: flex;

    .name {
      flex: 0 1 auto;
      width: 48%;
      margin-right: 2%;
    }

    .city {
      flex: 0 1 auto;
      width: 50%;
    }
  }

  .product-and-rating {
    display: flex;

    .product {
      flex: 0 1 auto;
      width: 48%;
      margin-right: 2%;
    }

    .rating {
      flex: 0 1 auto;
      width: 50%;
    }
  }
}


#views-form-commerce-cart-form-default {
  table {
    thead {
      @include media-xs {
        display: none;
      }
    }

    tbody {
      @include media-xs {
        tr {
          display: flex;
          flex-flow: wrap;

          td {
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-end;
            width: 100%;

            &:nth-child(1) {
              justify-content: flex-start;
            }

            &:nth-child(2)::before {
              font-weight: bold;
              display: inline;
              margin-right: auto;
              content: 'Наименование';
            }

            &:nth-child(3)::before {
              font-weight: bold;
              display: inline;
              margin-right: auto;
              content: 'Цена';
            }

            &:nth-child(4)::before {
              font-weight: bold;
              display: inline;
              margin-right: auto;
              content: 'Количество';
            }

            &:nth-child(5)::before {
              font-weight: bold;
              display: inline;
              margin-right: auto;
              content: 'Итого';
            }
          }
        }
      }
    }
  }
}

#commerce-checkout-form-checkout,
#commerce-checkout-form-review {
  table {
    thead {
      @include media-xs {
        display: none;
      }
    }

    tbody {
      @include media-xs {
        tr {
          display: flex;
          flex-flow: wrap;

          td {
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-end;
            width: 100%;

            &:nth-child(1) {
              justify-content: flex-start;
            }

            &:nth-child(2)::before {
              font-weight: bold;
              display: inline;
              margin-right: auto;
              content: 'Цена';
            }

            &:nth-child(3)::before {
              font-weight: bold;
              display: inline;
              margin-right: auto;
              content: 'Количество';
            }

            &:nth-child(4)::before {
              font-weight: bold;
              display: inline;
              margin-right: auto;
              content: 'Итого';
            }
          }
        }
      }
    }
  }
}

.view-commerce-cart-form-default {
  .content {
    table {
      thead {
        @include media-xs {
          display: none;
        }
      }

      tbody {
        @include media-xs {
          tr {
            display: flex;
            flex-flow: wrap;

            td {
              display: flex;
              flex: 1 1 auto;
              justify-content: flex-end;
              width: 100%;

              &:nth-child(1) {
                justify-content: flex-start;
              }

              &:nth-child(2)::before {
                font-weight: bold;
                display: inline;
                margin-right: auto;
                content: 'Название';
              }

              &:nth-child(3)::before {
                font-weight: bold;
                display: inline;
                margin-right: auto;
                content: 'Цена за ед.';
              }

              &:nth-child(4)::before {
                font-weight: bold;
                display: inline;
                margin-right: auto;
                content: 'Количество';
              }

              &:nth-child(6)::before {
                font-weight: bold;
                display: inline;
                margin-right: auto;
                content: 'Итого';
              }
            }
          }
        }
      }
    }
  }

  .views-field-line-item-title {
    a {
      font-weight: bold;
    }
  }

  .line-item-summary {
    font-size: 24px;
  }

  .commerce-line-item-actions {
    .form-submit {
      @include media-xs {
        margin-bottom: 16px;
      }
    }
  }

  #edit-checkout {
    @include button-variant(#64dd17);
    font-size: 18px;
    padding: 8px 16px;
  }
}

.button-operator {
  margin: 0 8px;
}

.customer_profile_billing {
  display: block;
  width: 100%;
  max-width: 100%;

  .fieldset-wrapper {
    input {
      width: 100%;
    }
  }
}

#commerce-checkout-form-review {
  .checkout-buttons {
    .fieldset-wrapper {
      align-items: center;
      lost-flex-container: row;
    }

    .help {
      font-size: 20px;
      line-height: 1.4;
      margin-left: auto;
      text-align: center;

      @include media-xs {
        margin: 24px auto 0 auto;
      }
    }
  }
}