/**
 * @file
 * Text classes.
 */

/**
 * text-* classes.
 */
.text-subtle {
  color: $text-color-subtle;
}
.text-highlight {
  color: $text-color-highlight;
}
.text-info {
  color: $text-color-info;
}
.text-success {
  color: $text-color-success;
}
.text-warning {
  color: $text-color-warning;
}
.text-error {
  color: $text-color-error;
}

/**
 * highlight-* classes.
 */
.highlight {
  padding: 1px 4px;
  font-weight: bold;
  text-shadow: none;
  border-radius: $border-radius-small;
  color: $highlight-color;
  background: $highlight-bg;
}
.highlight-info {
  @extend .highlight;
  color: $highlight-info-color;
  background: $highlight-info-bg;
}
.highlight-success {
  @extend .highlight;
  color: $highlight-success-color;
  background: $highlight-success-bg;
}
.highlight-warning {
  @extend .highlight;
  color: $highlight-warning-color;
  background: $highlight-warning-bg;
}
.highlight-error {
  @extend .highlight;
  color: $highlight-error-color;
  background: $highlight-error-bg;
}
