.paragraphs-item-faq {
  margin-bottom: 15px;

  .question-pane {
    padding-left: 40px;
    position: relative;
    @include icon($fa-var-chevron-down);
    cursor: pointer;

    &:before {
      width: 30px;
      height: 30px;
      line-height: 27px;
      text-align: center;
      border: 2px solid $gray;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .name {
      font-weight: bold;
      margin-bottom: 10px;
      padding-top: 3px;
    }

    .question {
      font-weight: bold;
      padding-top: 3px;
      margin-bottom: 10px;
      //cursor: pointer;
      -webkit-user-select: none;  /* Chrome all / Safari all */
      -moz-user-select: none;     /* Firefox all */
      -ms-user-select: none;      /* IE 10+ */
      user-select: none;
    }
  }

  .answer {
    margin-left: 60px;
    border-radius: 4px;
    background: $message-status-border;
    border: 1px solid $message-status-border;
    color: $text-color;
    padding: 10px;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 500ms, opacity 500ms;
  }

  &.opened {
    .answer {
      max-height: 1500px;
      opacity: 1;
      transition: max-height 500ms, opacity 500ms;
    }

    .question-pane {
      &:before {
        color: $message-status-color;
        border-color: $message-status-color;
      }
    }
  }
}