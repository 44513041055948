.product-knife-teaser {
  background: white;
  padding: 10px;
  min-width: 0;

  .title {
    @include font-size(15);
    margin: 0 0 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 41px;

    a {
      text-decoration: underline;
    }
  }

  .photo {
    margin: -10px -10px 10px -10px;
    min-height: 100px;

    img {
      width: 100%;
    }
  }

  .field-commerce-price {
    @include font-size(16);
    color: $ui-site-color-3;
    font-weight: bold;
    span {
      color: $text-color;
      font-weight: normal;
    }
  }

  .buy-in-one-click {
    font-size: 13px;
    line-height: 1;
    color: black;
    display: inline-block;
    border-bottom: 1px dashed black;

    @include media-xs-sm {
      border: 2px solid $ui-site-color-3;
      display: block;
      padding: 8px;
      color: $ui-site-color-3;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .footer {
    display: grid;
    grid-gap: 16px;
    align-items: center;
    margin-top: 16px;
  }

  .price-buy {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include media-md {
    .footer {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include media-xs {
    .title {
      @include font-size(14);
    }
  }
}

.product-knife-full {
  .first-pane {
    @include susy-clearfix;
    @include media-xs {
      display: flex;
      flex-flow: wrap;
    }

    > .left {
      min-width: 0;

      @include media-sm {
        @include span(6);
      }

      @include media-md {
        @include span(9);
      }

      .field-field-product-knife-photos {
        .first-photo {
          margin-bottom: 15px;
          position: relative;
          @include icon($fa-var-search-plus);

          &:before {
            position: absolute;
            top: 20px;
            right: 20px;
            color: $ui-site-color-3;
            text-shadow: 0 0 30px black;
            margin: 0;
            font-size: 20px;
            height: 0px;
            overflow: visible;
            pointer-events: none;
          }
        }
      }
    }

    > .right {
      min-width: 0;

      @include media-sm {
        @include span(6 last);
      }

      @include media-md {
        @include span(3 last);
      }

      @include media-xs {
        width: 100%;
        flex: 1 1 auto;
      }

      .steel-and-handle {
        @include font-size(14px);
        margin-bottom: 10px;

        .commerce-product-field-field-product-knife-steel,
        .commerce-product-field-field-product-knife-handle {
          margin-bottom: 3px;

          .label {
            font-weight: bold;
            display: inline-block;
          }

          .value {
            display: inline-block;
          }
        }
      }

      .cart-wrapper {
        background: #e8e8e8;
        padding: 10px;
        margin-bottom: 15px;

        .cart-wrapper {
          margin-bottom: 16px;
        }

        .field-commerce-price {
          text-align: center;
          color: $ui-site-color-3;
          @include font-size(32);
          font-weight: bold;

          .label-inline {
            margin-right: 10px;
            color: black;
            font-weight: normal;
            font-size: 18px;
          }

          .currency {
            font-size: 18px;
          }
        }

        .commerce-add-to-cart {
          .in-stock {
            margin-bottom: 15px;
            text-align: center;
            @include icon($fa-var-check-circle-o);
            color: #318a33;
            font-size: 13px;
          }

          .form-radios {
            @extend .product-id-select-radios;
          }

          .form-item-quantity {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);

            label {
              font-weight: normal;
              margin-right: 10px;
            }

            input {
              padding: 3px 10px;
            }
          }

          .form-submit {
            @include button-variant($ui-site-color-3);
            font-weight: bold;
            padding: 6px;
            width: 100%;
          }

          .buy-in-one-click {
            color: $ui-site-color-3;
            display: block;
            text-align: center;
            margin-top: 8px;
            border: 1px solid $ui-site-color-3;
            padding: 4px 8px;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .second-pane {
    @include susy-clearfix;
    @include media-xs {
      display: flex;
      flex-flow: wrap;
    }

    > .left {
      min-width: 0;

      iframe {
        width: 100%;
      }

      @include media-xs {
        order: 2;
      }
      @include media-sm {
        @include span(6);
      }

      @include media-md {
        @include span(9);
      }
    }

    > .right {
      min-width: 0;
      width: 100%;

      .specification {
        margin-bottom: 15px;

        > h3 {
          color: $ui-site-color-3;
          margin-top: 0;
          @include font-size(20);
        }

        .commerce-product-field {
          &:not(.commerce-product-field-empty) {
            @include susy-clearfix;
            padding: 3px;
            @include font-size(14);

            .label {
              @include span(7);
            }

            .value {
              @include span(4 last);
              text-align: right;
            }
          }

          &.even {
            background: rgba(black, .1);
          }
        }
      }

      @include media-sm {
        @include span(6 last);
      }

      @include media-md {
        @include span(3 last);
      }

      @include media-xs {
        order: 1;
      }
    }
  }

  .field-group-htabs {
    .group-desc {
      .fieldset-wrapper {
        line-height: 19px;
      }
    }
  }

  .commerce-product-field-field-product-additional-params {
    padding: 0 !important;

    .odd {
      background: #d3d0cb;
    }
  }

  .social-likes {
    margin-top: 15px;

    .label {
      font-size: 14px;
      display: block;
      font-family: "Roboto",sans-serif;
      width: 100%;
      margin-left: 0.45em;
    }

    .social-likes__widget {
      margin: 0.35em;
    }
  }

  .tag-categories {
    margin-top: 15px;

    > h4 {
      @extend h3;
      color: $ui-site-color-3;
    }

    .item {
      margin-bottom: 6px;
      font-size: 13px;

      .label-inline {
        font-weight: bold;
      }

      a {
        font-weight: bold;
      }
    }
  }
}

.product-knife-full .similar-products {
  margin-bottom: 24px;
}


.product-knife-full .other-photos {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 32px;

  @include media-md {
    grid-template-columns: repeat(4, 1fr);
  }
}