.article-teaser {
  margin-bottom: 15px;

  .promo {
    margin-bottom: 10px;
  }

  .title {
    margin: 0 0 10px;
    @include font-size(16);

    @include media-md {
      @include font-size(20);
    }

    a {
      border-bottom: 1px solid $ui-site-color-3;
    }
  }

  .teaser {
    @include font-size(14);
    line-height: 16px;
  }
}

.article-full {
  .promo {
    margin-bottom: 15px;
  }

  .paragraphs {
    margin-bottom: 48px;
  }

  .actual-categories {
    &__labels {
      text-align: center;
      text-transform: uppercase;
    }

    &__items {
      lost-flex-container: row;
    }

    &__item {
      lost-waffle: 3/12 4;
    }
  }

  .similar-articles {
    &__label {
      text-align: center;
      text-transform: uppercase;
    }

    &__items {
      lost-flex-container: row;
    }

    .article-teaser {
      lost-waffle: 3/12 4;
    }
  }
}
