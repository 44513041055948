#sidebar-first,
#sidebar-second {
  background: $ui-site-color-4;
  color: white;
  display: none;

  @include media-md {
    display: block;
  }

  a {
    color: white;
  }

  > .block {
    padding: 10px;
    border-bottom: 1px solid rgba($ui-site-color-1, .5);
    margin-bottom: 10px;

    > .title {
      @include font-size(16);
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .menu {
      margin: 0;
      padding: 0;
      list-style: none;
      width: calc(100% + 20px);
      transform: translateX(-10px);

      li {
        a {
          display: block;
          padding: 2px 10px;
          line-height: 19px;

          &:hover {
            text-decoration: underline;
          }

          &.active {
            color: white;
            background: $ui-site-color-3;
          }
        }
      }
    }
  }

  .facetapi-facetapi-checkbox-links {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

#sidebar-first {
  display: block;
  order: 2;

  @include media-md {
    order: initial;
  }
}

#block-current-search-standard {
  .active {
    @include button-variant($ui-site-color-3, transparent, black, white);
    width: 100%;
    background: $ui-site-color-3 !important;
    color: white !important;
    border: none !important;
  }
}