body {
  padding-top: 48px;

  @include media-md {
    padding-top: 0;
  }
}

#app {
  @include media-containers();
  background: $ui-site-color-1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);

  .modal-open & {
    filter: blur(7px);
  }
}

#content {
  width: 100%;
  padding: 20px;
}

#main {
  display: grid;
  grid-gap: 0px;

  @include media-xs {
    grid-template-columns: 1fr;
  }

  @include media-sm-md {
    grid-template-columns: 1fr;
  }

  @include media-md {
    .sidebar-first & {
      grid-template-columns: 20% 1fr;
    }

    .sidebar-second & {
      grid-template-columns: 1fr 20%;
    }

    .sidebar-second.sidebar-first & {
      grid-template-columns: 20% 1fr 20%;
    }

    .no-sidebars & {
      grid-template-columns: 1fr;
    }
  }
}

#page-title {
  margin-top: 0;
}

.video-replacer {
  @include icon($fa-var-youtube-play);
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  cursor: pointer;

  &:before {
    font-size: 70px;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    margin: 0;
    transition: color 500ms;
    transform: translate(-50%, -50%);
    color: rgba(black, .85);
  }

  &.replaced {
    &:before {
      display: none;
    }
  }

  &:hover {
    &:before {
      transition: color 500ms;
      color: #e52d27;
    }
  }

  .preview {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .image {
      position: absolute;
      z-index: -1;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
    }
  }
}

.search-api-ranges-widget {
  .yui3-g {
    @include susy-clearfix;

    .range-box-left {
      @include span(3);
    }

    .range-slider-box {
      @include span(6);
      margin-top: 34px;

      .ui-slider-handle {
        width: 6px;
        margin: 0;
        background: white;
      }
    }

    .range-box-right {
      @include span(3 last);
    }

    .range-box-left,
    .range-box-right {
      input {
        font-size: 12px;
        padding: 4px;
      }
    }
  }
}