.backdrop-entityform {
  opacity: 1 !important;
  background: rgba(black, 0.6) !important;
}

.modal-entityform {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  max-width: 100%;

  .modal-wrapper {
    max-width: 700px;
    background: white;

    .modal-header {
      background: $ui-site-color-4;
      color: white;
      padding: 10px 15px;

      .modal-title {
        font-weight: bold;
      }

      .close {
        @include icon($fa-var-close);
        float: right;
        color: white;
      }
    }

    .modal-content {
      padding: 15px;

      .form-actions {
        width: calc(100% + 30px);
        transform: translate(-15px, 15px);
        padding: 15px;
        background: $ui-site-color-1;
      }
    }
  }
}

.form-item-fz152-agreement {
  font-size: 12px;
}