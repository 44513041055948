$social: (
  'vk': ($fa-var-vk, #45668E),
  'facebook': ($fa-var-facebook, #3B5999),
  'ok': ($fa-var-odnoklassniki, #ED812B),
  'instagram': ($fa-var-instagram, #DA306E)
);

.contact {
  &__title {
    font-weight: bold;
    margin-top: 60px;
  }

  &__first-row {
    margin-bottom: 30px;
  }

  &__label {
    color: gray;
    font-size: 16px;
  }

  &__telephones {
    margin-bottom: 15px;
  }

  &__telephone {
    display: block;
    font-size: 24px;
    line-height: 1.5;
    color: #333;
    font-weight: bold;

    a {
      color: #333;
    }
  }

  &__emails {
    margin-bottom: 15px;
  }

  &__email {
    display: block;
    font-size: 24px;
    line-height: 1.5;
    color: #333;
    font-weight: bold;
  }

  &__social-items {
    margin-top: 8px;
    display: flex;
  }

  &__social-item {
    color: white;
    font-size: 18px;
    padding: 4px 8px;
    display: block;
    margin-right: 8px;
    border-radius: 4px;
  }

  @each $class, $icon in $social {
    &__social-item--#{$class} {
      background: nth($icon, 2);
      @include icon(nth($icon, 1));

      &:hover {
        background: lighten(nth($icon, 2), 10%);
        color: white;
      }

      &::before {
        margin: 0;
      }
    }
  }

  &__address {
    margin-bottom: 30px;
  }

  &__details-label {
    font-weight: bold;
  }

  &__form {
    display: block;
    margin: 0 auto;
    max-width: 400px;
    padding: 20px;
    background: #e6e4e1;
    border: 1px solid #b9b9b9;
    min-width: 0;
  }

  &__map {
    max-width: 700px;
    min-width: 0;
  }

  @include media-md {
    &__first-row {
      lost-flex-container: row;
      margin-bottom: 30px;
    }

    &__first-row-left {
      lost-column: 6/12;
    }

    &__first-row-right {
      lost-column: 6/12;
    }

    &__details-and-photo {
      lost-flex-container: row;
    }

    &__details {
      lost-column: 8/12;
    }

    &__photo {
      lost-column: 4/12;
    }
  }

  @include media-xs-sm {
    &__details-and-photo {
      display: block;
    }

    &__photo {
      margin-top: rem(16);
    }

    &__telephone {
      font-size: 18px;
    }
  }
}
