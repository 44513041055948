.commerce-order-handler-area-order-total .commerce-price-formatted-components {
  width: 100%;
}

.commerce-order-handler-area-order-total .commerce-price-formatted-components .component-title {
  text-align: right;
}

.commerce-order-handler-area-order-total .commerce-price-formatted-components .component-total {
  width: 100px;
}