#navigation,
#footer-navigation {
  background: $ui-site-color-2;
  color: white;
  display: none;

  @include media-md {
    display: block;
  }
}

#footer-navigation {
  //border-top: 2px solid $ui-site-color-3;
  //border-bottom: 2px solid $ui-site-color-3;
}