#block-germes-shopping-cart {
  .cart-wrapper {
    border: 1px solid #9E9E9E;
    padding: 15px;
    @include font-size(14);
    @include icon($fa-var-shopping-cart);
    position: relative;

    &:before {
      position: absolute;
      top: -20px;
      left: -20px;
      color: $ui-site-color-5;
      background: $ui-site-color-1;
      display: block;
      width: 40px;
      height: 40px;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
    }

    > .title {
      color: $ui-site-color-5;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 6px;
    }

    .order-checkout {
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 6px;
      border-bottom: 2px solid $ui-site-color-3;
      display: inline-block;
      color: $ui-site-color-3;
    }
  }
}