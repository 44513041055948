.page-404 {
  display: flex;
  align-items: center;
  height: 100vh;
}

.page-404-content {
  display: table;
  margin: 0 auto;
}

.page-404-content .code {
  font-size: 38px;
  text-align: center;
}

.page-404-content .text {
  font-size: 28px;
  text-align: center;
}

.page-404-content .home {
  text-align: center;
  display: block;
}