.paragraphs-item-gallery {

  > .title {
    color: $ui-site-color-3;
  }

  .photos {
    @include susy-clearfix;

    .photo {
      border: 1px solid $ui-site-color-5;
      margin-bottom: 15px;

      @include media-xs {
        @include gallery(6);
      }

      @include media-sm-md {
        @include gallery(3);
      }

      img {
        width: 100%;
      }
    }
  }
}