#block-block-2 {
  .title {
    @include icon($fa-var-envelope-open);
    color: $ui-site-color-5;
    position: relative;
    font-size: 14px;
    margin-bottom: 10px;

    &:before {
      font-size: 20px;
      line-height: 1;
      color: $ui-site-color-5;
    }
  }

  .email {
    color: $ui-site-color-2;
  }
}