/**
 * @file
 * Styles whic you want to use more thatn few times and they always the same.
 */

.product-id-select-radios {
  .form-type-radio {
    input {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;

      &:checked + label {
        position: relative;
        border-color: $ui-site-color-3;
        background-color: lighten($ui-site-color-3, 40%);
        //color: darken($brand-success, 10%);
        box-shadow: 0 0 0 3px $ui-site-color-3;
        overflow: hidden;

        &:before {
          @extend .fa;
          @extend .fa-check;
          display: block;
          position: absolute;
          left: 0;
          bottom: 1px;
          z-index: 10;
          font-size: 10px;
          color: white;
        }

        &:after {
          display: block;
          content: '';
          width: 30px;
          height: 30px;
          color: #FFF;
          background: $ui-site-color-3;
          transform: rotate(45deg);
          position: absolute;
          bottom: -16px;
          left: -16px;
        }
      }
    }

    label {
      border: 1px solid #cecdcd;
      display: block;
      padding: 3px 20px;
      cursor: pointer;

      .price {
        font-weight: bold;
      }
    }
  }
}

// Horizontal tabs.
.horizontal-tabs {
  border: none;

  .horizontal-tabs-list {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    list-style: none;

    .horizontal-tab-button {
      display: inline-block;

      a {
        display: block;
        @include font-size(24);
        font-weight: normal;
        border-radius: 0 10px 0 0;
        padding: 5px 15px;
        color: black;

        strong {
          font-weight: normal;
          border-bottom: 1px dashed black;
        }
      }

      &.selected {
        a {
          color: $link-color;
          background: $ui-site-color-6;

          strong {
            border: none;
          }
        }
      }
    }
  }

  .horizontal-tabs-panes {
    .horizontal-tabs-pane {
      background: $ui-site-color-6;
      margin: 0;
      border: none;

      &.horizontal-tab-hidden {
        display: none;
      }

      legend {
        display: none;
      }

      .fieldset-wrapper {
        margin: 0;
      }
    }
  }
}

.responsive-video-16-9 {
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.material-shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

.material-shadow-1-with-hover {
  @extend .material-shadow-1;
  transition: box-shadow .2s ease-in-out;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .23);
  }
}

.material-shadow-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
}

.material-shadow-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .23);
}

.material-shadow-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
}

.material-shadow-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, .3), 0 15px 12px rgba(0, 0, 0, .22);
}