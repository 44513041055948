#block-menu-block-2 {
  .menu {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    @include flexbox;
    @include flex-flow();
    @include justify-content();
    z-index: 100;

    > li {
      @include flex(1, 1, auto);

      &.active {
        > a {
          background: $ui-site-color-3;
        }
      }

      &:hover {
        > a {
          background: darken($ui-site-color-3, 10%);
          transition: background 300ms;
        }
      }

      a {
        display: block;
        padding: 10px 12px;
        color: white;
        text-align: center;
        background: transparent;
        transition: background 300ms;
      }
    }
  }
}