#block-menu-block-1 {
  .menu-block-wrapper {
    > .menu {
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      @include flexbox;
      @include flex-flow();
      @include justify-content();
      z-index: 100;

      > li {
        @include flex(1, 1, auto);

        &.active {
          > a {
            background: $ui-site-color-3;
          }
        }

        &:hover {
          > a {
            background: darken($ui-site-color-3, 10%);
            transition: background 300ms;
          }
        }

        > a {
          display: block;
          padding: 10px 12px;
          color: white;
          text-align: center;
          background: transparent;
          transition: background 300ms;
        }

        &.expanded {
          > a {
            @include susy-clearfix;
            @include icon($fa-var-caret-down, after);

            &:after {
              line-height: 1;
            }
          }

          &:hover {
            .menu {
              display: block;
            }
          }

          > .menu {
            display: none;
            margin: 0;
            padding: 20px;
            list-style: none;
            position: absolute;
            z-index: 100;
            max-width: 320px;
            min-width: 200px;
            background: white;
            box-shadow: 0 2px 6px 0 rgba(black, .1);
            border: 1px solid rgba(black, .2);
            border-top: none;

            li {
              width: 100%;
              display: block;
              margin-bottom: 10px;

              &:last-child {
                border: none;
                margin: 0;
              }

              a,
              span {
                color: #666;
                border-bottom: 1px solid rgba(#666, .5);
                transition: color 500ms, border-color 500ms;

                &:hover {
                  background: rgba(white, .2);
                  color: $text-color;
                  border-color: $text-color;
                  transition: color 500ms, border-color 500ms;
                }
              }
            }

            .menu {
              padding: 0;
              margin: 0;
              margin-top: 5px;
              margin-left: 10px;
              font-size: 13px;

              li {
                a {
                  display: inline;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}