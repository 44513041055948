.germes-rating {
  display: flex;
  align-items: center;

  &__star {
    @include icon($fa-var-star-o);

    &.is-active {
      @include icon($fa-var-star);
      color: $ui-site-color-3;

      &::before {
        margin: 0;
      }
    }

    &::before {
      margin: 0;
    }
  }
}

.germes-rating__label {
  margin-left: 8px;
}
