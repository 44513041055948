#frontpage {
  @include flexbox();
  @include flex-direction(column);

  .pane-title {
    color: $ui-site-color-3;
  }

  .videos {
    .pane-content {
      @include susy-clearfix;

      .col {
        margin-top: 16px;
        @include media-sm {
          @include gallery(6);
        }

        @include media-md {
          @include gallery(6);
        }

        .title {
          @extend h3;
          margin-top: 0;
          font-weight: bold;
        }

        .video {
          @extend .responsive-video-16-9;
        }
      }
    }
  }

  .bestsellers {
    @include media-xs {
      @include flex(1);
      @include order(-2);
    }
  }

  .categories {
    @include media-xs {
      @include flex(1);
      @include order(-1);
    }
  }

  .advantages {
    .pane-title {
      color: $ui-site-color-3;
    }

    .pane-content {
      @include media-sm-md {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
      }
    }
  }

  .catalog {
    .pane-title {
      color: $ui-site-color-3;
    }

    .catalog-link {
      text-transform: uppercase;
      border-bottom: 1px solid $ui-site-color-3;
    }
  }

  .why {
    .pane-title {
      color: $ui-site-color-3;
    }

    .pane-content {
      @include media-sm-md {
        @include flexbox;
      }

      .item {
        //border: 1px solid $ui-site-color-3;
        border-radius: 4px;
        background: $body-bg;
        margin: 50px 5px 0;
        position: relative;
        padding: 50px 10px 10px 10px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .icon {
          //border: 1px solid $ui-site-color-3;
          border-radius: 50%;
          background: $body-bg;
          width: 75px;
          height: 75px;
          display: table;
          margin: 0 auto;
          transform: translate(-50%, -50%);
          position: absolute;
          top: 0;
          left: 50%;

          .image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .title {
          font-family: $font-family-condensed;
          text-align: center;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 15px;
        }
      }
    }
  }

  .reviews {
    .pane-title {
      color: $ui-site-color-3;
    }
  }

  .testimonial-teaser {
    .pane-title {
      color: $ui-site-color-3;
    }
  }

  .pane-current-13 {
    margin-bottom: 30px;
  }
}

.frontpage-exhibition {
  &__scheme {
    margin-bottom: 24px;
  }
}