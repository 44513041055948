#mobile-sidebar {
  min-width: 320px;
  max-width: 320px;
  background: white;
  z-index: 1001;

  .header {
    //background-image: url('../../images/mobile-sidebar-bg.jpg');
    //background-size: cover;
    background-color: $ui-site-color-1;
    color: #5d6064;
    position: relative;

    .logo {
      display: table;
      margin: 0 auto;
      padding: rem(20) rem(20);
    }
  }

  .footer {
    background: #f1f1f1;
    border-top: 1px solid #e2e2e2;
    padding: 10px;

    .slogan {
      @include font-size(17);
      text-align: center;
      color: $ui-site-color-1;
      font-weight: bold;
      font-style: italic;
      margin-bottom: rem(10);
    }
  }
}

.sn-backdrop {
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: none;
  opacity: 0;
  z-index: 50;
  will-change: opacity;
}

.sn-visible .sn-backdrop {
  display: block
}

.sn-sidenav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  overflow-y: auto;
  will-change: transform;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6)
}

.sn-content {
  position: relative;
  z-index: 10;
}

.sn-visible, .sn-visible body, .sn-visible .sn-content {
  overflow: hidden;
}

#germes-mobile-search {
  display: flex;
  padding: 16px;
}

#germes-mobile-search .button {
  background: $ui-site-color-2;
  color: white;
  border: none;
}